import { ReactComponent as Logo } from '../images/logo.svg';
import { Link } from "react-router-dom";

import React from 'react';


import Footer from '../components/footer';

const Privacy = () => {

    return (
        <div className="min-h-full flex flex-col flex-center justify-center bg-white">            
            <main className="relative bg-slate-50 py-8 md:py-12 px-4">
                <Link to="/" className="flex items-center justify-center gap-1 mb-8">
                    <Logo className="w-auto h-12 md:h-16" />
                    <h1 className='text-gray-700 text-xl md:text-3xl font-bold pt-3'>TailwindReference</h1>
                </Link>
                <div className="mx-auto max-w-4xl flex flex-col justify-center items-center p-12 bg-white rounded-sm border border-gray-200">
                    <div className="mb-12">
                        <svg xmlns="http://www.w3.org/2000/svg" height="7em"  viewBox="0 0 512 512">
                            <path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" fill="#00CC99"/>
                        </svg>
                    </div>

                    <div className="text-center mb-12">
                        <h1 className="text-2xl md:text-4xl font-extrabold text-gray-800 mb-8">Contact</h1>
                        <p className="text-md text-gray-700">You can contact via email for your issues related with TailwindReference.com. You can give also your feedback.</p>
                    </div>


                    <a href="mailto:contact@tailwindReference.com" className="bg-[#687AE8] text-white text-sm p-4 rounded-full">
                        <span className="text-md font-normal tracking-wide">Contact@tailwindReference.com</span>
                    </a>
                </div>
            </main>

            <Footer />
        </div>
    )

}

export default Privacy;
