import React from "react";

export const Toast = ({ text }: { text: string }) => {

    return (
        <div
            className="fixed z-50 p-4 m-4 font-medium text-white rounded-sm shadow-lg top-20 right-0 border border-white bg-gray-500"
            role="alert"
        >
            <p className="text-sm">"{text}" copied into your clipboard </p>
        </div>
    );
};