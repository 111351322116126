import React from 'react';
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from '../images/logo.svg';


const NoMatch = () => (

    <div className="min-h-screen flex flex-col flex-center justify-center bg-white">
        <main className="flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex-shrink-0 flex justify-center">
            <a href="/" className="inline-flex">
                <span className="sr-only">Workflow</span>
                <Logo className="w-auto h-16" />
            </a>
            </div>
            <div className="py-8">
                <div className="text-center">
                    <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">404 error</p>
                    <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Page not found.</h1>
                    <p className="mt-2 text-base text-gray-500">Sorry, we couldn’t find the page you’re looking for.</p>
                    <div className="mt-6">
                        <Link className="text-base font-medium text-indigo-600 hover:text-indigo-5000" to="/">Go back home</Link>
                    </div>
                </div>
            </div>
        </main>
    </div>
);

export default NoMatch;
