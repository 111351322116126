import React from 'react';
import CategoryType from "../modules/models/category";
import Subcategory from '../modules/models/subcategory';
import SubcategoryComponent from './subcategory';

const Category = ({ category } : { category: CategoryType }) => {
  return (
    <div className={"rounded-sm bg-white p-4 m-2 overflow-hidden shadow-xl border border-gray-200"}>
      <h1 className={"px-3 py-2 mb-2 font-medium text-[#292D32] tracking-wider bg-gray-50 border border-gray-200 shadow-sm rounded-sm"}>
        {category.title}
      </h1>
      {
        category.content.map((subcategory: Subcategory, index: Number) => {
          return subcategory.table.length > 0 && <SubcategoryComponent key={'Subcat-' + index} subcategory={subcategory} />;
        })
      }
    </div>
  )
};

export default Category;
