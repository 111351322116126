import React, { useRef, useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../images/logo.svg';
import { dispatch } from 'use-bus';
import { Link } from "react-router-dom";

let searchTimeout: number | null = null
function clearSearch() {
    if (searchTimeout !== null) {
        clearTimeout(searchTimeout)
    }
}

const SearchBar = ({ searchFilter }: { searchFilter : ( text:string ) => void }) => {
    const searchInputRef = useRef<HTMLInputElement>(null);
    
    const handleFocus = (e: KeyboardEvent) => {
        if (e.key === "k" && (e.ctrlKey || e.metaKey)) {
            //preventDefault if ctrl + k is already binded to any browser function
            e.preventDefault();
            searchInputRef?.current?.focus();
        }

        if (e.key === "Escape") {
            searchInputRef?.current?.blur();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleFocus);
        return () => {
            document.removeEventListener("keydown", handleFocus);
        };
    }, []);

    // The search is currently very expensive, as it redraws many elements on
    // the screen. This little wrapper adds an artificial delay so that the
    // app doesn't block user input.
    const search = (event: any) => {
        const text: string = event.target.value.toLowerCase();
        if (text.length < 5) {
            clearSearch()
            searchTimeout = window.setTimeout(() => searchFilter(text), 300)
        } else {
            clearSearch()
            searchFilter(text)
        }
    }

    const clearInput = () => {
        const inputElement = searchInputRef?.current
        if (inputElement) {
            inputElement.value = ''
            clearSearch()
            searchFilter('')
        }
    }

    let shouldRenderClearBtn = false
    const length = searchInputRef?.current?.value?.length
    if (length !== undefined && length > 0) {
        shouldRenderClearBtn = true
    }

    return (
        <div className="bg-white border-b border-gray-200 lg:fixed lg:w-full lg:top-0 lg:z-50 lg:left-0">
            <div className="flex justify-center items-center flex-col mx-auto p-4">
                <div className="w-full flex items-center justify-between h-10">

                    <Link to="/" className="flex items-center gap-1">
                        <Logo className="w-auto h-11" />
                        <h1 className='text-gray-700 text-xl md:text-2xl font-bold pt-3'>TailwindReference</h1>
                    </Link>

                    <div className="hidden lg:block relative mt-4 sm:w-96 w-full sm:mx-auto h-10 my-4">
                        <input
                            ref={searchInputRef}
                            className="w-full h-full bg-white border border-gray-200 rounded-sm peer bg-gray-50 text-gray-600 border-gray-300 focus:border-[#687AE8] focus:border-[#687AE8] focus:outline-none focus:ring focus:ring-[#687AE8] placeholder-gray-400 focus:ring-opacity-20"
                            type="text"
                            placeholder="Search"
                            onChange={search}
                            autoFocus
                        />
                        {shouldRenderClearBtn ? (
                            <button onClick={clearInput} className="absolute text-gray-500 -translate-y-1/2 right-2 focus:outline-none top-1/2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </button>
                        ) : (
                            <span className="absolute px-2 py-1 text-xs text-gray-600 transition-opacity duration-75 -translate-y-1/2 border rounded-sm pointer-events-none py- border-gray-300 right-2 top-1/2 peer-focus:opacity-0">
                                Ctrl k
                            </span>
                        )}
                    </div>

                    <div className="flex items-center justify-between gap-2">
                        <div onClick={() => dispatch('ui/expand')} className='w-9 h-9 bg-[#00CC99] cursor-pointer flex items-center justify-center rounded-sm border border-white shadow-sm hover:opacity-90'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M160 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V64zM32 320c-17.7 0-32 14.3-32 32s14.3 32 32 32H96v64c0 17.7 14.3 32 32 32s32-14.3 32-32V352c0-17.7-14.3-32-32-32H32zM352 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H352V64zM320 320c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32s32-14.3 32-32V384h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320z" fill='#fff'/></svg>                        
                        </div>
                        <div onClick={() => dispatch('ui/collapse')} className='w-9 h-9 bg-[#00CC99] cursor-pointer flex items-center justify-center rounded-sm border border-white shadow-sm hover:opacity-90'>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                            <path d="M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z" fill='#fff'/></svg>                        
                        </div>

                        <a title="Tailwind Gradient" href="https://tailwindgradient.com/" target='_blank' className="hidden lg:block py-2 px-4 text-sm font-medium text-white transition-colors duration-300 transform rounded-sm border border-white shadow-sm bg-[#687AE8] hover:opacity-90">Tailwind Gradient</a>
                    </div>
                </div>
            </div>
            <div className="block lg:hidden flex justify-center items-center flex-col mx-auto p-4">
                <div className="w-full flex items-center justify-between h-10">
                    <div className="relative mt-4 sm:w-96 w-full sm:mx-auto h-10 my-4">
                        <input
                            ref={searchInputRef}
                            className="w-full h-full bg-white border border-gray-200 rounded-sm peer bg-gray-50 text-gray-600 border-gray-300 focus:border-[#687AE8] focus:border-[#687AE8] focus:outline-none focus:ring focus:ring-[#687AE8] placeholder-gray-400 focus:ring-opacity-20"
                            type="text"
                            placeholder="Search"
                            onChange={search}
                            autoFocus
                        />
                        {shouldRenderClearBtn ? (
                            <button onClick={clearInput} className="absolute text-gray-500 -translate-y-1/2 right-2 focus:outline-none top-1/2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </button>
                        ) : (
                            <span className="absolute px-2 py-1 text-xs text-gray-600 transition-opacity duration-75 -translate-y-1/2 border rounded-sm pointer-events-none py- border-gray-300 right-2 top-1/2 peer-focus:opacity-0">
                                Ctrl k
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
        
    );
};

export default SearchBar;
