import React from "react";

const Tagline = () => {

    // Share on Social media //
    var pageLink = "https://www.TailwindReference.com";
    var pageTitle = "TailwindReference.com - Ultimate Tailwind CSS cheatsheet and reference guide"

    const fbs_click = () => { window.open(`http://www.facebook.com/sharer.php?u=${pageLink}&quote=${pageTitle}`,'sharer','toolbar=0,status=0,width=626,height=436');return false; }
        
    const tbs_click = () => { window.open(`https://twitter.com/intent/tweet?text=${pageTitle}&url=${pageLink}`,'sharer','toolbar=0,status=0,width=626,height=436');return false; }

    const rbs_click = () => { window.open(`https://www.reddit.com/submit?url=${pageLink}`,'sharer','toolbar=0,status=0,width=626,height=436');return false; }

    const copy_click = () => {
        const textToCopy = "https://www.TailwindReference.com";
        navigator.clipboard.writeText(textToCopy)
          .then(() => {
            const notification = document.getElementById("copyNotification");
            if (notification){
                notification.classList.remove("hidden");

                setTimeout(function() {
                    notification.classList.add("hidden");
                }, 3000)
            }

          })
          .catch(function(error) {
            console.error('Error copying to clipboard: ', error);
          });
      }
      
    return (
        <section className="bg-gray-100 pt-14 pb-8 lg:pt-32 lg:pb-4">
            <h1 className="p-6 text-5xl font-extrabold text-center text-[#687AE8]">
                Tailwind CSS Reference
            </h1>

            <p className="max-w-4xl px-4 mx-auto mt-4 text-md text-center text-gray-600 md:text-lg">
                Get quick access to all the class names and CSS properties with this interactive cheat sheet. The only Tailwind Reference you will ever need!
            </p>

            <p className="max-w-6xl px-4 mx-auto mt-4 text-center text-gray-500 text-sm md:text-md">
                Your ultimate Tailwind CSS cheatsheet and reference guide 
            </p>

            <div className="flex items-center justify-center gap-2 z-20 mt-10">
                <button onClick={fbs_click} className="flex items-center justify-center gap-2 h-10 w-10 rounded-full shadow text-sm font-bold text-white bg-[#1773EA] border-2 border-white cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 h-5" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>
                </button>

                <button onClick={tbs_click} className="flex items-center justify-center gap-2 h-10 w-10 rounded-full shadow text-sm font-bold text-white bg-[#000000] border-2 border-white cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 h-5" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                </button>

                <button onClick={rbs_click} className="flex items-center justify-center gap-2 h-10 w-10 rounded-full shadow text-sm font-bold text-white bg-[#F74300] border-2 border-white cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 h-5" viewBox="0 0 512 512"><path d="M440.3 203.5c-15 0-28.2 6.2-37.9 15.9-35.7-24.7-83.8-40.6-137.1-42.3L293 52.3l88.2 19.8c0 21.6 17.6 39.2 39.2 39.2 22 0 39.7-18.1 39.7-39.7s-17.6-39.7-39.7-39.7c-15.4 0-28.7 9.3-35.3 22l-97.4-21.6c-4.9-1.3-9.7 2.2-11 7.1L246.3 177c-52.9 2.2-100.5 18.1-136.3 42.8-9.7-10.1-23.4-16.3-38.4-16.3-55.6 0-73.8 74.6-22.9 100.1-1.8 7.9-2.6 16.3-2.6 24.7 0 83.8 94.4 151.7 210.3 151.7 116.4 0 210.8-67.9 210.8-151.7 0-8.4-.9-17.2-3.1-25.1 49.9-25.6 31.5-99.7-23.8-99.7zM129.4 308.9c0-22 17.6-39.7 39.7-39.7 21.6 0 39.2 17.6 39.2 39.7 0 21.6-17.6 39.2-39.2 39.2-22 .1-39.7-17.6-39.7-39.2zm214.3 93.5c-36.4 36.4-139.1 36.4-175.5 0-4-3.5-4-9.7 0-13.7 3.5-3.5 9.7-3.5 13.2 0 27.8 28.5 120 29 149 0 3.5-3.5 9.7-3.5 13.2 0 4.1 4 4.1 10.2.1 13.7zm-.8-54.2c-21.6 0-39.2-17.6-39.2-39.2 0-22 17.6-39.7 39.2-39.7 22 0 39.7 17.6 39.7 39.7-.1 21.5-17.7 39.2-39.7 39.2z"/></svg>
                </button>

                <button onClick={copy_click} className="relative flex items-center justify-center gap-2 h-10 w-10 rounded-full shadow text-sm font-bold text-white bg-[#00CC99] border-2 border-white cursor-pointer">
                    <div id="copyNotification" className="absolute hidden -top-11 bg-[#374151] w-24 text-xs text-white p-2 rounded mt-2">URL Copied!</div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 h-5" viewBox="0 0 448 512"><path d="M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z"/></svg>
                </button>
            </div>
        </section>
        )

}

export default Tagline;
