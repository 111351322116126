import React from 'react';
import { ReactComponent as Logo } from '../images/logo.svg';
import { Link } from "react-router-dom";


const Footer = () => (
    <footer className="bg-white border-t border-gray-200 w-full px-4">
        <div className="container mx-auto py-3 flex flex-col items-center justify-center md:flex-row md:justify-between gap-4">
            <div className="flex items-center gap-1">
                <Logo className="w-auto h-9" />
                <h1 className='text-gray-700 text-md md:text-lg font-bold pt-3'>TailwindReference</h1>
            </div>

            <div className="">
                <p className="text-center text-sm text-gray-600">&copy; {(new Date().getFullYear())} ManageText.com, All rights reserved.</p>
            </div>

            <div className="flex items-center gap-2">
                <Link className="text-center text-sm tracking-wide text-gray-600 hover:text-blue-500" to="/privacy">Privacy Policy</Link>
                <Link className="text-center text-sm tracking-wide text-gray-600 hover:text-blue-500" to="/contact">Contact</Link>
            </div>
        </div>
    </footer>
);

export default Footer;
